import React, { useMemo } from 'react'
import { connect } from 'formik'
import styled, { keyframes } from 'styled-components'
import Button from 'components/Button'
import { isEmpty } from 'ramda'
import Link from '../Link'

const animation = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`

const Spinner = styled.div`
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    width: 1em;
    height: 1em;
    background-color: white;
    border-radius: 100%;
    display: inline-block;
    animation: ${animation} 1.4s infinite ease-in-out both;
    :nth-child(0) {
      animation-delay: -0.32s;
    }
    :nth-child(1) {
      animation-delay: -0.16s;
    }
  }
`

function ConditionalLink ({ condition, children, ...linkProps }) {
  return condition ? <Link {...linkProps}>{children}</Link> : <>{children}</>
}

function StoryblokFormSubmit ({ data, formik }) {
  const { link, enableLink } = useMemo(
    () => {
      if (!isEmpty(formik)) return {} // prohibit use as link for formik children
      return { link: data?.link, enableLink: data?.enableLink }
    },
    [data, formik]
  )
  return (
    <ConditionalLink link={link} condition={enableLink}>
      <Button type="submit" disabled={formik.isSubmitting}>
        {formik.isSubmitting ? (
          <Spinner>
            <div />
            <div />
            <div />
          </Spinner>
        ) : (
          data.label
        )}
      </Button>
    </ConditionalLink>
  )
}
export default connect(StoryblokFormSubmit)
